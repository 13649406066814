@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/iconmoon/icomoon.eot?i6xg7e');
  src:  url('../../fonts/iconmoon/icomoon.eot?i6xg7e#iefix') format('embedded-opentype'),
    url('../../fonts/iconmoon/icomoon.ttf?i6xg7e') format('truetype'),
    url('../../fonts/iconmoon/icomoon.woff?i6xg7e') format('woff'),
    url('../../fonts/iconmoon/icomoon.svg?i6xg7e#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.icon-man:before {
  content: "\e900";
}
.icon-tablet:before {
  content: "\e901";
}
.icon-youtube:before {
  content: "\e902";
}
.icon-instagram:before {
  content: "\e903";
}
.icon-pinterest:before {
  content: "\e904";
}
.icon-linkedin:before {
  content: "\e905";
}
.icon-twitter:before {
  content: "\e906";
}
.icon-facebook:before {
  content: "\e907";
}
.icon-mail:before {
  content: "\e908";
}
.icon-envelope:before {
  content: "\e909";
}
.icon-mobile:before {
  content: "\e90a";
}
.icon-slide-left:before {
  content: "\e90b";
}
.icon-slide-right:before {
  content: "\e90c";
}
.icon-menu:before {
  content: "\e90d";
}
.icon-close:before {
  content: "\e90e";
}
.icon-circle-left:before {
  content: "\e90f";
}
.icon-circle-right:before {
  content: "\e910";
}
.icon-arrow-right:before {
  content: "\e911";
}
.icon-arrow-left:before {
  content: "\e912";
}
.icon-hotel:before {
  content: "\e913";
}
.icon-globe:before {
  content: "\e914";
}
.icon-phone:before {
  content: "\e915";
}
.icon-angle-down:before {
  content: "\e916";
}
.icon-angle-double-right:before {
  content: "\e917";
}
.icon-map-point:before {
  content: "\e918";
}
.icon-phone-header:before {
  content: "\e919";
}
.icon-plus:before {
  content: "\e91a";
}
.icon-question-square:before {
  content: "\e91b";
}
.icon-envelope-open:before {
  content: "\e91c";
}
.icon-magnify:before {
  content: "\e91d";
}
.icon-new-file:before {
  content: "\e91e";
}
.icon-long-arrow-right:before {
  content: "\e91f";
}
.icon-envelope-open1:before {
  content: "\e920";
}
.icon-sign-out:before {
  content: "\e921";
}
.icon-sign-in:before {
  content: "\e922";
}
.icon-x-twitter:before {
  content: "\e923";
}


