footer {
    color: $text_gray;
    font-family: $rubik;
    font-weight: $regular;
    font-size: 16px;
    margin-top: 63px;

    .social-links {
        padding-top: 1.5em;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;

        a {
            width: 36px !important;
            height: 36px !important;

            &:before {
                font-size: 16px !important;
            }
        }
    }

    .footer {
        &-btn-label {
            color: $base1;
            font-family: $rubik;
            font-weight: $medium;
            font-size: 18px;
        }

        &-btn-wrap {
            position: absolute;
            z-index: 111;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            .btn {
                border-radius: 10px;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                width: auto;
                top: -60px;
                position: relative;
            }

            .footer-btn-label {
                margin-right: 30px;
            }

            & .btn:nth-child(1) {
                margin-right: 10px;
            }
        }

        &-first-row {
            margin-bottom: 50px;

            .main-text {
                font-family: $open-sans;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.5;
            }
        }

        &-text {
            &-left {
                color: $light_gray;
                position: relative;
                //font-size: 15px;
                //font-weight: normal;
                //font-family: $open-sans;

                span {
                    display: inline-block;
                    margin-right: 30px;
                    position: relative;
                }

                .divider {
                    position: absolute;
                    border-right: 1px solid $light_gray;
                    height: 100%;
                    margin: 0;
                    right: -15px;
                }
            }

            &-right {
                //font-size: 15px;
                //font-weight: normal;
                //font-family: $open-sans;
                text-align: right;

                a {
                    color: $light_gray;
                    margin-left: 7px;
                    transition: all .3s;

                    &:hover {
                        color: $blue_link;
                    }
                }
            }
        }

        &-top {
            background: $footer_blue;

            .btn {
                width: 240px;
            }


            h3 {
                @include fluid-font(24px);
                color: $white;
                font-family: $work-sans;
                font-weight: $bold;
                //text-transform: uppercase;
                margin-bottom: 30px;
            }

            &-line {
                height: 15px;
                background: $text_brown;
            }

            .container {
                padding-top: 1rem;
                padding-bottom: 2.2rem;
                position: relative;

                .row {
                    flex-wrap: wrap;

                    .col {
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5) {
                            padding-top: 3.5rem;
                        }

                        &:nth-child(1) {
                            flex: 0 0 27%;
                        }

                        &:nth-child(2) {
                            flex: 0 0 18%;
                        }

                        &:nth-child(3) {
                            flex: 0 0 24%;
                        }

                        &:nth-child(4) {
                            flex: 0 0 25%;
                        }

                        .logo-container {
                            // margin-bottom: 40px;
                            img {
                                max-width: 50%;
                            }
                        }

                        .footer-about {
                            font-weight: normal;
                            font-family: $open-sans;
                            //@include rlh(26px);
                            line-height: 2;
                            margin-bottom: 1.2rem;
                        }

                        .footer-contacts, .footer-twitter-news {
                            @include rlh(26px);

                            span {
                                //@include fluid-font(17px);
                            }

                            li {
                                font-family: $karla;
                                font-weight: $regular;
                                font-size: 15px;
                                color: $text_violet;
                                position: relative;
                                padding-left: 30px;
                                margin-bottom: 1em;
                            }

                            i {
                                position: absolute;
                                display: inline-block;
                                line-height: inherit;
                                left: 0;
                                top: 0;
                                color: $base2;

                                &.icon-question-square {

                                }

                                &.icon-phone {
                                    font-size: 14px;
                                }

                                &.icon-mobile, &.icon-question-square, &.icon-map-point {
                                    font-size: 18px;
                                }
                            }
                        }

                        .footer-twitter-news {
                            li {
                                font-family: $open-sans;
                                font-weight: $regular;
                                font-size: 13px;

                                .text {
                                    color: $text_violet;
                                }

                                i, .tags {
                                    color: $base1;
                                }

                                i {
                                    top: 5px;
                                }
                            }
                        }

                        .footer-quick-links {
                            text-transform: uppercase;
                            font-weight: $regular;
                            @include fluid-font(16px);
                            @include letter-spacing(100);
                            display: inline-block;
                            text-align: left;

                            li {
                                margin-bottom: 1em;
                            }

                            a {
                                &:hover, &.active {
                                    color: $base1;
                                }
                            }
                        }

                        .footer-icon-links {
                            a {
                                display: flex;
                                align-items: center;
                                margin-bottom: 2.5rem;
                                @include fluid-font(20px);
                                font-weight: normal;
                                font-family: $work-sans;
                                justify-content: flex-start;

                                i {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-right: 5px;
                                    width: 3.5rem;
                                    height: 3.5rem;
                                    flex: 0 0 3.5rem;
                                    font-size: 1.5rem;
                                    @include transition;
                                }

                                &:hover {
                                    i {
                                        background-color: $base1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &-bottom {
            background: $black;
            color: $light_gray;
            //font-family: $montserrat;
            padding-top: 1.5em;
            padding-bottom: 1.5em;

            p {
                margin-bottom: 0;
                @include letter-spacing(200);
            }
        }
    }

    @include bdown(lg) {
        .footer {
            &-top {
                .col {
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        padding: 0 30px;
                    }

                    &:nth-child(2) {
                        padding-right: 0;
                    }

                    &:nth-child(4) {
                        border-right: none;
                        padding-right: 0;
                    }
                }
            }
        }
    }

    @include bdown(nm) {
        .footer {
            &-top {
                .social-links {
                    padding: 1.5em 0;
                }

                .col {
                    &:nth-child(1) {
                        flex: 0 0 40% !important;
                    }

                    &:nth-child(3) {
                        border-right: none;
                        padding-right: 0;
                    }

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5) {
                        padding: 0 20px;
                    }

                    &:nth-child(5) {
                        border-right: none;
                        padding-right: 0;
                        flex: 0 0 100%;
                        padding-top: 0;
                    }
                }
            }
        }
    }

    @include bdown(md) {
        .footer {
            &-top {
                .footer-contacts-wrap {
                    display: flex;
                    justify-content: center;

                    .footer-contacts {
                        max-width: 230px;
                    }
                }

                .footer-service-wrap {
                    max-width: 350px;
                    margin: auto;
                }

                .footer-quick-links {
                    column-count: 2;
                }

                .container {
                    padding: 30px;
                }
                .social-links {
                    padding: 0;
                }

                .btn {
                    top: -50px;
                    width: 165px;
                    height: 50px;

                    &:nth-child(1) {
                        margin-right: 10px;
                    }
                }

                .container {
                    .row {
                        display: flex;

                        .col {
                            padding-left: 15px !important;
                            padding-right: 15px !important;
                            margin-bottom: 30px;
                            flex-basis: 100% !important;

                            h3 {
                                margin-bottom: 20px;
                            }

                            .social-links {
                                padding-left: 0;
                                justify-content: center;
                                padding-bottom: 21px;
                            }
                        }
                    }

                    .logo-container, .footer-about {
                        text-align: center;
                        margin-bottom: 1rem;
                    }
                }
            }

            &-btn {
                &-wrap {
                    //top: 5px;
                    justify-content: center;
                }

                &-label {
                    display: none;
                }
            }

            &-bottom {
                padding: 16px 0;

                .row {
                    padding: 0;
                }

                .footer-text-left {
                    font-size: 11px;
                    margin-bottom: 12px;
                }

                .footer-text-right {
                    a {
                        margin-right: 20px;
                    }
                }

                .footer-text-left, .footer-text-right {
                    text-align: center;
                }
            }
        }
    }

    @include bdown(xs) {
        .footer {
            &-btn-wrap {
                .btn span {
                    font-size: 15px;
                }

                .btn i, .btn .vertical-sep {
                    display: none;
                }
            }
        }
    }
}