section.cf {
    position: relative;
    background-position: right 2rem;
    background-repeat: no-repeat;
    &--right {
        background-position: left 2rem;
        form {
            margin-left: auto;
        }
    }
    &:after {
        content: "";
        background-image: linear-gradient(rgba($color: #F6F7F5, $alpha: 1 ), rgba($color: #EDEFEC, $alpha: 1 ), rgba($color: #DFE1DE, $alpha: 0 ));
        position: absolute;
        left: 0;
        top: 0;
        height: 30%;
        width: 100%;
    }
    &.clear {
        background-position: right top;
        &:after {
            content: none;
        }
    }
    form {
        position: relative;
        z-index: 5;
    }
}

form {
    input, select, textarea {
        &.has-danger {
            border: 1px solid #f00 !important;
        }
    }
    .thankyou-url {
        display: none;
    }
    .required {
        color: #f00;
    }
}

.footer-top {
    form.hsf {
        ::placeholder {
            //color: $blue;
        }
        @include bup(md) {
            padding: 2rem 4rem;
            max-width: none;
            margin-left: auto;
            margin-right: auto;
        }
        input, select, textarea {
            //color: $blue;
        }
        .btn {
            //font-family: $oswald;
            font-size: 12px;
            font-weight: 700;
            @include letter-spacing(150);
            padding: 1rem;
        }
    }
}

form.hsf {
    @extend %letter-form;
    @include bup(xs){
        padding-left: 20%;
        background-image: url(../../../images/icons/form-question.svg);
        background-repeat: no-repeat;
        background-position: left center;
        background-color: #fff;
        background-size: 15%;
    }
    ::placeholder {
        text-transform: uppercase;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        transition: background-color 5000s ease-in-out 0s;
        // -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    .hsf-pretitle {
        //font-family: $lora;
        @include fluid-font(26px);
        color: $base1;
        font-weight: 700;
        padding-bottom: .3rem;
        border-bottom: 1px solid #929292;
        display: inline-block;
    }
    // input::-internal-autofill-selected {
    //     background-color: transparent !important;
    // }

    label {
        //color: $blue;
        display: block;
    }
    input, select, textarea {
        // color: $violet;
        // text-transform: uppercase;
    }
    .col {
        margin-bottom: 1rem;
    }
    .bottom-info {
        color: #707070;
        font-size: 14px;
    }
    .form-inner {
        @extend %block-shadow-1;
    }
    button.link-more {
        background-color: transparent;
        border: none;
        padding-top: .5rem;
        @include fluid-font(18px);
    }
}

form#login-form{
    @extend %letter-form;
    .row {
        margin-bottom: 1rem;
    }
    .col {
        align-items: center;
        &.vcenter {
            display: inline-flex;
            margin-bottom: 5px;
        }
    }
    label {
        font-family: $montserrat;
        @include fluid-font(18px);
        font-weight: 700;
        @include letter-spacing(100);
    }
    input, select, textarea {
        background-color: #fff;
        padding: 0 1rem;
        font-weight: 700;
        @include fluid-font(18px);
        border-color: transparent;
        font-family: $montserrat;
    }
    input {
        line-height: 45px;
    }
    .btn {
        //background-color: $blue;
        &:hover {
            //background-color: $green;
        }
    }

}

form.default-form {
    @extend %default-form;

    &#contact-us {
        //padding: 2rem 2rem 4rem;
        h4 {
            font-weight: 600;
        }
    }
}

form.write-form {
    background: rgba($base2, 0.07);
    padding: 50px;
    box-shadow: 0px 0px 20px 0px #ddd;

    input, textarea {
        background: $white;
    }
}

form.ask-your-librarian {
    @extend %default-form;
    .row {
        .col {
            label {
                color: #525252;
                @include fluid-font(19px);
                .sub {
                    //font-family: $lora;
                    font-style: italic;
                    display: block;
                    @include fluid-font(20px);
                }
            }
            input, select, textarea {
                border: 1px solid #9B918A;
            }
            textarea {
                background-color: #eae5dc
            }
        }
    }
}

form.register.default-form {
    margin-top: 2rem;

    .btn {
        @include bup(md){
            margin-left: 2rem;
        }
    }

    .row .col {
        margin-bottom: 0 !important;
        .select-wrapper {
            //background-color: $base1 !important;
            border-radius: 5px;
            overflow: hidden;
            &:before {
                content: "\e916";
                font-family: $icomoon;
                font-size: 2em;
                color: $white;
            }
            &:after {
                content: none;
            }
        }
    }
}

.pencil {
    .col {
        position: relative;
        &:after {
            position: absolute;
            top: 1.75rem;
            transform: translateY(-50%);
            content: "\e92d";
            right: 2rem;
            font-family: $icomoon;
            // color: $violet;
        }
    }

}

.checkbox {
    input[type="checkbox"] {
        opacity: 0;
        display: none;
    }

    label {
        position: relative;
        display: flex !important;
        align-items: center;
        height: 30px;
        padding-left: 37px;
        font-family: $rubik;
        font-weight: $medium;
        color: $base1;
        font-size: 16px;
        cursor: pointer;
        user-select: none;

        &:before, &:after {
            position: absolute;
            content: "";
            display: inline-block;
        }

        &:before{
            height: 30px;
            width: 30px;
            border: 1px solid $base2;
            left: 0;
            top: 0;
        }

        &:after {
            height: 12px;
            width: 16px;
            border-left: 4px solid $base2;
            border-bottom: 4px solid $base2;
            transform: rotate(-45deg);
            left: 7px;
            top: 7px;
        }
    }

    input[type="checkbox"] + label::after {
        content: none;
    }

    input[type="checkbox"]:checked + label::after {
        content: "";
    }
}

.upload {
    &--wrap {
        width: 100%;
        position: relative;
        margin: 15px 0;
        text-align: center;

        .text {
            line-height: 1;
            margin-top: 1px;
        }
    }

    &--input {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        width: 1px;
        height: 1px;
    }

    &--label {
        width: 100%;
        color: #fff;
        @include fluid-font(23px);
        font-weight: $regular;
        font-family: $rubik;
        display: flex !important;
        align-items: center;
        justify-content: flex-start;
        border-radius: 3px;
        cursor: pointer;

        img {
            margin-right: 20px;
        }

        .btn {
            margin-right: 30px;
        }
    }
}