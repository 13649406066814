.slider {
    position: relative;
    // min-height: 180px;
    width: 100%;
    text-decoration: none;

    @include bdown(md) {
        min-height: 0;
    }

    &-underline {
        height: 10px;
        background: $blue_second_base;
    }

    &-overlay {
        background: rgba( $slider_overlay, .6);
        width: 100%;
        height: 100%;
        position: absolute;
    }
}

#about-slider {
    .slick-dots {
        .slick-dot {
            background: $white;
        }

        .slick-active {
            .slick-dot {
                background: $base1;
                border: 2px solid $base1;
            }
        }
    }

    .inner {
        left: auto !important;

        @include bdown(md) {
            transform: none;
        }
    }

    .slider-item {
        max-height: none;
    }

    .about--slider-info {
        max-width: 80%;
        margin: auto;

        img {
            //object-fit: cover;
            width: 107px;
            height: 112px;
            margin: auto;
            margin-bottom: 10px;
        }

        .title {
            font-family: $work-sans;
            font-weight: $semibold;
            @include fluid-font(24px);
            color: $base2;
            text-align: center;
            margin-bottom: 20px;
        }

        p {
            font-family: $rubik;
            font-weight: $regular;
            font-size: 16px;
            color: $white;
            text-align: center;
        }

        @include bdown(md) {
            max-width: 100%;
        }
    }

    .slider-overlay {
        background: rgba( $slider_overlay, .92);
    }

    .block--title-wrap {
        p {
            color: $white;
        }

        .title-big {
            color: $white;
            max-width: 40%;
            margin: auto;
        }

        @include bdown(md) {
            .title-big, p {
                max-width: 100%;
            }
        }
    }
}

.slick-slide {
    &:focus {
        outline: none;
    }

    .slide-container {
        background: $white;
        border-radius: 10px;
        box-shadow: 1px 5px 15px 0 #eaeaea;
        cursor: pointer;
        @include transition;

        &:hover {
            background: $base1;

            .pb-text, .pb-title, .pb-name, .pb-position {
                color: $white;
            }

            .sliding-content {
                top: 0;
            }

            .pb-item--container:before {
                height: 0;
            }
        }
    }
}

.slick-track {
    display: flex;
    // align-items: center;
}

.slider-instance {
    &.init {
        opacity: 0;
        height: 80px;
    }
}

.slick-dots {
    display: block;
    li {
        display: inline-block;
        padding: 0 7px;
    }

    .slick-dot {
        width: 15px;
        height: 15px;
        background: transparent;
        border: 2px solid $white;
        border-radius: 50%;
        position: relative;

        &:hover {
            color: $base1;
        }
    }

    .slick-active {
        .slick-dot {
            background: $base1;
            border: 2px solid $base1;
        }
    }

    @include bdown(md){
        .slick-dot {
            width: 10px;
            height: 10px;
        }
    }
}

.main-slider {
    @include transition;

    &.slider-instance.init {
        height: 400px;
    }

    @extend %slider-arrow-style;

    .big {
        .inner {
            padding-bottom: 8rem !important;
            padding-top: 8rem !important;

            @include bbetween(sm, lg) {
                padding: 1.5rem 2.5rem 4.5rem 2.5rem!important;
            }

            @include bdown(sm) {

                padding: 2rem 2em !important;
                width: 80% !important;
            }
        }
    }

    .middle {
        .inner {
            padding-bottom: 4rem !important;
            padding-top: 4rem !important;
        }
    }

    .small {
        min-height: auto !important;

        .slider-overlay {
            background: rgba($slider_overlay, .9) !important;
        }

        .inner {
            padding-bottom: 2rem !important;
            padding-top: 2rem !important;
            background: transparent !important;

            @include bdown(md) {
                width: 100% !important;
                align-items: center;
            }
        }
    }

    .slider-item {
        position: relative;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        max-height: 100vh;

        &.position-right {
            .description .inner {
                &.empty {
                    display: none;
                }
            }
        }
    }

    .description {
        height: 100%;
        z-index: 15;

        .inner {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            //background: rgba($slider_overlay, .8);
            height: 100%;
            padding: 0 2.5rem;
            //width: 50%;
            //max-width: 630px;
            box-sizing: border-box;
            left: 15px;

            .slider-btn-wrap {
                position: absolute;
                bottom: 0;

                .btn {
                    margin-right: 10px;
                    border-radius: 10px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            @media screen and (max-width: 1700px) {
                left: 130px;
            }

            @include bdown(nm) {
                left: 85px;
            }
        }

        .slider-title-block {
            @extend %slider-title-block;
            line-height: 1;
        }
        .slider-buttons-block {
            margin-top: 2rem;
        }
    }
    
    .slick-dots {
        position: absolute;
        bottom: .5rem;
        left: 50%;
        transform: translateX(-50%);
    }

    &.dots-up {
        @include bup(md){
            .slick-dots {
                bottom: 3rem;
            }
        }
    }

    @include bup(md) {
        .description .inner {
            //min-width: 630px;
        }
        &.no-mh {
            .slider-item {
                min-height: 0;
            }
        }
    }

    @include bup(sm) {
        .description {
            .slider-title-block {
                p {
                    max-width: 610px;
                }
            }
        }
    }

    @include bdown(md) {
        .description {
            .inner {
                width: 100% !important;
                left: 50%;
                transform: translateX(-50%);
                top: 0;
            }
        }
        &.no-min-height {
            .slider-item {
                min-height: 200px;
            }
        }
    }

    @include bdown(sm) {
        .description {
            .inner {
                left: 0;
                transform: none;
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            .slider-buttons-block {
                margin: 20px 0;

                .btn {
                    height: auto;
                    width: 100%;
                    max-width: 175px;
                }

                .slider-button {
                    margin: .3em 0;
                    padding: .5em 1em;
                }
            }
        }
    }

    @include bdown(xs) {
        .description {
            .slider-title-block {
                .title-2 {
                    padding-top: .15em;
                    padding-bottom: .15em;
                }
                p {
                    margin-bottom: 1em;
                }
            }
        }
        .slick-arrow {
            display: none !important;
        }
    }
}

#looking-for-slider {
    .slick-arrow {
        &:before {
            color: rgba($base2, 0.5);
        }
    }

    .slider-item {
        max-height: 100%;

        .col {
            margin-bottom: 40px;

            &.hidden--mobile {
                margin-bottom: 0;
            }
        }
    }

    .slick-dots {
        .slick-dot {
            border: 2px solid $base1;
        }
    }
}

.capabilities {
    .col {
        padding: 0;
    }
    @include bup(md) {
        .img-container {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                display: block;
                background: rgba( $slider_overlay, .6);
            }

            img {
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &.left {
                flex: 1 1 25%;
            }

            &.right {
                flex: 1 1 50%;
            }
        }
    }

    @include bdown(md){
        .container.fw {
            padding-left: 0;
            padding-right: 0;
        }
        .col {
            margin-bottom: 0;
        }

        .row {
            padding-bottom: 0;
        }
    }

    &-slider--container{
        .capabilities-slider {
            background-color: $dark_blue_text;
            color: #fff;

            h3 {
                margin-bottom: 4rem;
                font-family: $work-sans;
                font-weight: 700;

                &:after {
                    background-color: #fff;
                }
            }
            .slick {
                &-dots {
                    position: absolute;
                    width: auto;
                    bottom: 3rem;
                    left: 50%;
                    transform: translateX(-50%);
                    display: flex;
                    padding: 0 1em;
                    li {
                        margin: 0 .25em;
                        a {
                            display: block;
                            width: 14px;
                            height: 14px;
                            border: 1px solid #fff;
                            border-radius: 50%;
                        }
                        &.slick-active {
                            a {
                                background-color: $base1;
                                border-color: $base1;
                            }
                        }
                    }
                }
            }

            .capabilities--list {
                
                li {
                    padding-left: 20%;
                    position: relative;
                    margin-bottom: 2rem;
                    color: $white;
                    font-family: $rubik;
                    font-weight:  $regular;

                    h4 {
                        color: $white;
                        font-family: $work-sans;
                        font-weight: 700;
                        margin-bottom: 20px;
                    }

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 15%;
                    }
                }
            }

        }
        @include bup(md){
            flex: 1 0 651px;
            max-width: 651px;
            .capabilities-slider {
                padding: 3rem 4rem 6rem;
                .capabilities--list {
                    min-width: 507px;
                }
            }
        }
        @include bdown(md){
            width: 100%;
            .capabilities-slider {
                padding: 1rem 2rem 2rem;
            }
        }
    }
}

.stories {
    @extend %stories-arrow-style;

    .col {
        padding: 0;
    }

    &-slider-wrap {
        justify-content: center;
        background: url(../../images/stories-bg.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        padding: 40px 0;

        .inner-wrap {
            //.title-big {
            //    text-align: center;
            //    color: $blue_second_base;
            //}

            .btn-wrap {
                display: flex;
                justify-content: center;
            }
        }

        &:before {
            content: "";
            background: linear-gradient(180deg, transparent 0%, rgba($dark_blue_text, .7) 70%);
            display: block;
            top: 0;
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    @include bdown(md){
        .container.fw {
            padding-left: 0;
            padding-right: 0;
        }
        .col {
            margin-bottom: 0;
        }

        .row {
            //padding-bottom: 0;
        }
    }

    &-slider--container{
        .stories-slider {
            background-color: transparent;
            padding-bottom: 3rem !important;

            .stories--card {
                background: $white;
                border-radius: 5px;
                padding: 30px;
                margin: 0 14px;
                box-sizing: border-box;
                padding-top: 60px;
                height: 100%;

                &-wrap {
                    padding-top: 25px;
                    position: relative;
                    height: 100%;

                    @include bdown(nm) {
                        max-width: 500px;
                    }
                }

                &:before {
                    display: block;
                    content: "";
                    background: url(../../images/quotes.svg);
                    background-size: cover;
                    background-position: center;
                    width: 75px;
                    height: 60px;
                    position: absolute;
                    top: 0;
                    right: 56px;
                }

                .inner {
                    p {
                        font-family: $work-sans;
                        font-weight: $medium;
                        color: $text_dark_gray;
                        line-height: 1.6;
                        @include fluid-font(20px);
                    }

                    a {
                        width: 100%;
                        text-align: right;
                        color: $base1;
                        font-family: $work-sans;
                        font-weight: $semibold;
                        font-size: 11px;
                        margin-bottom: 30px;
                        outline: none;

                        i {
                            margin-left: 10px;
                            font-size: 12px;
                        }
                    }

                    h3 {
                        font-family: $work-sans;
                        font-weight: $semibold;
                        color: $dark_blue_text;
                        @include fluid-font(22px);
                        margin-top: 30px;
                        margin-bottom: 7px;
                    }

                    h5 {
                        font-family: $rubik;
                        font-weight:  $regular;
                        color: $text_dark_gray;
                        @include fluid-font(16px);
                        margin: 0;
                    }
                }
            }

        }
        @include bup(md){
            flex: 1 0 1200px;
            max-width: 1200px;

            .stories-slider {
                padding: 3rem 4rem 6rem;
            }
        }

        @include bdown(nm){
            width: 100%;
            flex: 1 0 500px;
            max-width: 500px;
            margin: auto;

            .stories-slider {
                padding: 1rem 2rem 2rem;
            }
        }

        @include bdown(md){
            .stories-slider {
                padding: 1rem 2rem 2rem;
            }
        }
    }

    .slick-dots {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
}

.middle-slider {
    @extend %slider-arrow-style;
    &--inner {
        display: flex;
        position: relative;
        z-index: 5;
        h2, h3, h4 {
            color: inherit;
        }
        h2 {
            font-family: $montserrat;
            @include fluid-font(40px);
            @include letter-spacing(20);
        }
        h4 {
            color: #fff;
            display: inline-block;
            //border-bottom: 1px solid $light-blue;
            padding-bottom: .3em;
            margin-bottom: 1.5em;
        }
        h3 {
            //font-family: $ebg;
            margin-bottom: 1em;
        }
        p {
            @include fluid-font(16px);
        }
        .pr {
            padding-top: 2rem;
            .link-more {
                color: #fff;
                @include fluid-font(17px);
                &:after {
                    //color: $violet-alt;
                }
                &:hover {
                    //color: $violet;
                }
            }
        }
    }
    &--inner--image {
        flex: 0 0 50%;
        position: relative;
        //border: 1rem solid #fff;

        img {
            position: absolute;
            left: 0;
            width: 100%;
            top: 0;
            height: 100%;
            object-fit: cover;
        }
    }
    &--inner--info {
        flex: 0 0 50%;
        position: relative;
        color: $white;
        //background-color: $blue;
        padding: 5% 10% 5% 5%;
        //background-image: url(../../../images/icons/loudspeaker.svg);
        background-repeat: no-repeat;
        background-position: 95% 5%;
        background-blend-mode: luminosity;
    }
    .double-shadow-container {
        @extend %double-shadow;
        max-width: 50%;
        position: relative;
        height: 3.5rem;
        &:after {
            //background-image: url(../../../images/double-shadow-big.png);
            background-size: 100% 17.5rem;
            height: 17.5rem;
            bottom: -2.5rem;
        }
    }
    @include bdown(md){
        .slick-list {
            background-color: #d3cabb;
        }
        &--inner {
            flex-direction: column;
        }
        &--inner--image {
            flex: 0 0 100%;
            img {
                position: static;
                max-width: 100%;
                height: auto;
            }
        }
        .double-shadow-container {
            max-width: 100%;
            display: none;
        }
        .slick-arrow {
            top: 25%;
        }
    }
    .slick-track {
        align-items: normal;
    }
}

.events-slider,
.events-list {
    @extend %slider-arrow-style;
    .slick-slide {
        padding-bottom: 2rem;
    }
    &--date {
        flex: 0 0 30%;
        text-align: center;
    }
    &--weekday {
    }
    &--date {
        @include fluid-font(68px);
        //color: $violet;
    }
    &--month {
        font-family: $montserrat;
        padding-bottom: .5rem;
    }
    &--time {
        padding-top: .5rem;
        border-top: 1px solid rgba($color: #86D4EF, $alpha: .69);
        font-weight: 600;
    }
    &--inner {
        display: flex;
        max-width: 1800px;
        margin-left: auto;
        margin-right: auto;
        &--image {
            border: 1rem solid $white;
            flex: 1 1 40%;
            position: relative;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &--info {
            flex: 1 1 60%;
            background-color: rgba($color: #86D4EF, $alpha: .3);
            display: flex;
            flex-wrap: wrap;
            padding: 2rem;
            position: relative;
            &--text {
                flex: 0 0 70%;
                h4 {
                    //color: $violet;
                    //border-bottom: 1px solid $light-blue;
                    padding-bottom: .5rem;
                }
                h2 {
                    //color: $blue;
                    font-family: $montserrat;
                    @include fluid-font(28px);
                    @include letter-spacing(20);
                }
            }
            &--date {
                text-align: center;
                margin-right: 3rem;
            }

        }
    }
    .single-shadow-container {
        content: "";
        //background-image: url(../../../images/block-shadow.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -2rem;
        height: 2rem;
        pointer-events: none;
    }

    @include bdown(md){
        &--inner {
            flex-direction: column;
            &--image {
                img {
                    position: static;
                }
            }
            &--info--text {
                flex: 0 0 100%;
            }
        }
    }
}

.events-list {
    .pr {
        .btn {
            margin-right: 1.5rem;
        }
    }
}

.testimonials-slider {
    .slide-item {
        display: flex;
        align-items: center;
        padding-left: 3em;
        padding-right: 3em;
        & > * {
            flex: 1 1 auto;
        }
        .img-side {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 0 1 330px;
            margin-right: 5%;
            &:before {
                //color: $violet-alt;
                @include font-count(56, 95);
                margin-right: 10px;
                padding-bottom: 1em;
            }
        }
        .text-side {
            flex: 1 1 0;
            padding-top: 2.5em;
            p {
                @include font-count(17, 22);
                @include rlh(44px);
            }
            .name {
                @include font-count(24, 38);
                padding-right: 1em;
                //font-family: $vibes;
                //color: $violet-alt;
            }
            .position {
                font-family: $poppins;
                font-size: 14px;
                @include font-count(12, 14);
                //color: $blue;
                letter-spacing: .2em;
                font-weight: 600;
            }
        }
    }
    .slick {
        &-arrow {
            top: 25%;
            position: absolute;
            @include font-count(18, 36);
            z-index: 20;
            //color: $blue;
            &:hover {
                //color: $blue;
            }
        }
        &-prev {
            left: 0;
        }
        &-next {
            right: 0;
        }
        &-dots {
            max-width: 325px;
            text-align: right;
            li {
                display: inline-block;
                margin: 0;
            }
        }
    }
    @include bup(md){
        padding-left: 9%;
        padding-right: 9%;
    }
    @include bdown(md){
        .slide-item {
            padding-left: 1.5em;
            padding-right: 1.5em;
            .img-side {
                flex: 0 1 40%;
                &:before {
                    position: absolute;
                    top: 10px;
                }
            }
            .text-side {
                .tlc {
                    padding-bottom: 0;
                    padding-top: 1.5em;
                }
            }
        }
        .slick-dots {
            float: none;
            max-width: none;
            text-align: center;
        }
    }
    @include bdown(sm){
        .slide-item {
            flex-direction: column;
            .img-side {
                flex: 0 1 40%;
                &:before {
                    position: absolute;
                    top: 10px;
                }
            }
        }
    }
}

.partners-slider {
    padding-left: 5%;
    padding-right: 5%;
    .slick-track {
        align-items: center;
    }
    .slick-slide {
        // height: 70px;
        padding-left: 7px;
        padding-right: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        //background-color: #edfaff;
        img {
            margin-left: auto;
            margin-right: auto;
            mix-blend-mode: multiply;
            max-height: 150px;
        }
        .bottom-text {
            text-align: center;
            @include font-count(12, 15);
            font-weight: 600;
            margin-top: 1em;
        }
    }
    .slick-prev.icon,
    .slick-next.icon {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        color: $blue_link;

        &:before {
            @include transition;
            display: inline-block;
            text-align: center;
            @include font-count(32, 40);
            // @include fs-26;
        }
        &:hover {
            color: $base2;
        }
    }
    .slick-prev {
        left: 3%;
    }
    .slick-next {
        right: 3%;
    }
    @include bdown(xs) {
        padding-left: 12%;
        padding-right: 12%;
    }
}

.team {
    &--inner {
        max-width: 85%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: block;
        &:hover {
            .sliding-content {
                top: 0;
            }
        }
        img {
            width: 100%;
        }
    }

    &-slider {
        margin-left: auto;
        margin-right: auto;
        @include bup(md){
            max-width: 1674px;
            padding-left: 7%;
            padding-right: 7%;
        }
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
        .slick-slide {
            height: inherit;
        }
        .double-shadow {
            &:after {
                z-index: -1;
            }
            .inner {
                overflow: hidden;
                position: relative;
                //@include framed($light-blue, $violet);
            }
        }

        .slick-arrow {
            position: absolute;
            top: -7rem;
            bottom: auto;
            font-size: 2rem;
            color: rgba($back_link, .3);

            &:hover {
                color: $base1;
            }
            &.slick-prev {
                right: 20rem;
                left: auto;
            }
            &.slick-next {
                right: 17rem;
            }
            @include bdown(lg){
                &.slick-prev {
                    right: 15rem;
                }
                &.slick-next {
                    right: 12rem;
                }
            }
            @include bdown(nm){
                &.slick-prev {
                    right: 13rem;
                }
                &.slick-next {
                    right: 10rem;
                }
            }
        }

        .slick-dots {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            .slick-dot {
                border: 2px solid $base1;
            }
        }
    }

    &--info {
        text-align: center;
        h5 {
            //color: $blue;
            @include fluid-font(28px);
            font-family: $montserrat;
            @include letter-spacing(20);
        }
        p {
            @include fluid-font(20px);
            //font-family: $ebg;
            //color: $violet;
            font-style: italic;
        }
        a {
            @include fluid-font(15px);
            //color: $orange;
            font-weight: 600;
            &:after {
                content: "\e90a";
                font-family: $icomoon;
                padding-left: .5rem;
            }
        }
    }
}

.sliding-content {
    position: absolute;
    height: 100%;
    background-color: rgba($slider_overlay, .8);
    left: 0;
    width: 100%;
    top: 100%;
    transition: all .4s ease-in-out;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 2rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .lm {
        padding: .5rem 1rem;
        background-color: $white;
        font-size: 14px;
        font-weight: 500;
    }
}

.programs-slider, .stories-slider, .main-slider {
    .slick-list {
        display: flex;
    }

    .slick-slide {
        height: inherit;
    }
}

.programs-slider {
    .slide-container {
        height: 100%;
    }

    &.no-radius-top {
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        .pb-item {
            & > img, .sliding-content {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }

            &--container {
                &:before {
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                }
            }
        }
    }
}

.programs-slider, .team-slider {
    @include bup(md){
        padding-left: 8%;
        padding-right: 8%;
    }
    @include bdown(md){
        padding-left: 5%;
        padding-right: 5%;
    }
    &.slider-instance.init {
        height: 400px;
    }

    @extend %program-slider-arrow-style;
}

.offers-slider {
    @include bup(md){
        padding-left: 8%;
        padding-right: 8%;
    }

    @include bdown(md){
        padding-left: 5%;
        padding-right: 5%;
    }

    &.slider-instance.init {
        height: 400px;
    }

    @extend %slider-arrow-style;

    .slick-dots {
        text-align: center;
        margin-top: 30px;
    }

    .slick-active {
        .slick-dot {
            background: $blue_second_base;
            border: 2px solid $blue_second_base;
        }
    }
}