$images: '../../images';
//$icons: '#{$images}/icons';

$base1: #83B92E;
$dark_base1: #618922;
$base2: #2B8FD0;
$dark_base2: #0C73B6;
$dark_blue_text: #002749; //overlay
$white: #FFFFFF;
$red: #FF5E14;
$border: #B1E0FF;
$light_gray: #6E88A2;
$text_gray: #8897A6;
$text_dark_gray: #5F6A75;
$text_violet: #D4D4FF;
$black: #020F1D;
$footer_blue: #021D39;
$header_blue: #002742;
$slider_overlay: #122A43;
$border_color: #707070;
$alter_text_gray: #585858;
$body_color: #fff;
$back_link: #A2A0A0;
$input_bg: #F7F7F7;
$tile_btn: #F1F8FC;
$gray_bg: rgba(#374522, .04);

//old
$blue_second_base: #0C73B6;
$blue_second_dark: #084974;
$blue_link: #9FCEFD;
$text_brown: #887F67;
$text_main: #2C2C2C;
$text_minor: #40454A;
$footer-divider: #15BEF0;

// icons
$facebook: #3B5998;
$twitter: #7ED0E2;
$linkedin: #486AAE;
$pinterest: #e60023;
$gray_icon: #707070;

// dots-bg
$dot-size: 3px;
$dot-space: 9px;

//breakpoints
$breakpoints: (
    'hd': 1919px,
    'hg': 1600px,
    'lg': 1400px,
    'nm': 1240px,
    'md': 991px,
    'sm': 768px,
    'xs': 480px,
    'xxs': 385px
)!default;