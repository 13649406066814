header {
    transition: background-color 0.4s;

    .container {
        position: relative;
        width: 100%;
    }

    .custom-container {
        max-width: 1350px;
    }

    .row {
        align-items: center;

        &.full-h {
            height: 100%;
        }
    }

    .col {
        &.big-space {
            flex: 1 1 50%;
        }

        &.small-space {
            flex: 1 1 25%;
        }

        &.flex {
            display: flex;
        }

        &.flex-center {
            align-items: center;
        }
    }

    .cellphone,
    .mail {
        display: inline-block;
        font-weight: $regular;
        font-family: $rubik;
        color: $base2;
        padding-left: 25px;
        position: relative;
        font-size: 18px;
        white-space: nowrap;

        i {
            position: absolute;
            left: 0;
            top: 2px;
            font-size: 20px;
        }

        span {
            display: inline-block;
            margin-right: 60px;
        }

        .sep {
            position: absolute;
            height: 100%;
            display: block;
            border-right: 1px solid $border_color;
            top: 0;
            left: calc(100% - 30px);
        }

        &:last-child {
            span {
                margin-right: 0;
            }
        }

        &.tablet {
            display: none;
        }
    }

    .header {
        &--info-wrap {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        &-btn-wrap {
            position: relative;
            z-index: 111;
            top: 11px;
            display: flex;

            .btn {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                width: 200px;
                &.hidden {
                    display: none !important;
                }
                &-login,
                &-logout {
                    i {
                        margin-left: 10px;
                    }
                    background-color: #002742;
                }
                &-login {
                }
                &-logout {
                    flex-wrap: wrap;
                    .account-info {
                        display: block;
                        margin-top: 3px;
                        font-size: 0.7rem;
                    }
                }
            }

            .btn:nth-child(1),
            .btn:nth-child(2) {
                margin-right: 10px;
            }
        }

        &-icons {
            display: flex;
            margin-right: 80px;
            align-items: center;

            i,
            a {
                margin-right: 22px;
                color: #fff;
                font-size: 14px;
                transition: all 0.3s;

                &:hover {
                    color: $base1;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &-top {
            padding-top: 5px;
            padding-bottom: 8px;
            position: relative;
            display: flex;
            height: 40px;
            background: $header_blue;

            .nav-toggle {
                font-family: "icomoon";
                font-size: 2.8em;
                min-width: 25px;
                transition: transform 0.3s ease;
                transform-origin: 50% 50%;
                color: $white;

                &-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $base1;
                    height: 68px;
                    width: 68px;
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                &:before {
                    content: "\e90d";
                }

                &.close {
                    transform: rotate(-180deg);
                }

                &.close:before {
                    content: "\e90e";
                }
            }

            &-container {
                display: flex;
                justify-content: flex-end;
            }

            form {
                @include bup(xs) {
                    width: 350px;
                }
                @include bdown(xs) {
                    width: 100%;
                }

                //color: $blue;

                .search {
                    //border-bottom: 2px solid $blue;
                    display: flex;
                    align-items: center;
                    ::placeholder {
                        //color: $blue;
                    }
                    input {
                        max-width: 90%;
                        border: none;
                        background-color: transparent;
                        font-weight: 500;
                        //color: $blue;
                        @include fluid-font(23px);
                        padding: 0.5em 1em 0.2em 1em;
                        flex: 1 1 0;
                    }

                    button {
                        font-size: 25px;
                        background-color: transparent;
                        border: none;
                        flex: 0 0 auto;
                        padding: 0;
                        //color: $blue;
                        &:hover {
                            color: $base1;
                        }
                    }
                }
            }

            @include bup(md) {
                .top-links {
                    padding-right: 7%;
                }
                .top-phone-block {
                    margin-left: 5em;
                }

                .col {
                    &:nth-child(2) {
                        //flex: 1 1 50%;
                    }
                }
            }

            @include bbetween(md, lg) {
                .header-btn-wrap {
                    //top: -20px;
                }
            }

            @include bbetween(md, nm) {
                .top-links {
                    flex-direction: column;

                    .icon-mobile {
                        margin-bottom: 0.5rem;
                    }
                }
            }

            @include bdown(md) {
                padding-top: 0;
                padding-bottom: 0;
                height: 68px;

                .logo {
                    text-align: center;
                    width: 94px;
                    height: 94px;
                }

                .container {
                    justify-content: center;
                }

                .header-icons,
                .header-top-info {
                    display: none;
                }

                .header-top-container {
                    justify-content: center;
                }

                .header-btn-wrap {
                    top: -10px;

                    .btn {
                        width: auto;
                        height: 30px;
                    }
                }

                .col {
                    margin-bottom: 10px;
                }
                .col:nth-child(2) {
                    justify-content: space-between;
                }
                .col:nth-child(3) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 0;
                }
                .soc-icons {
                    display: none;
                }
                .top-links {
                    .separator,
                    span {
                        display: none;
                    }
                }
                .top-phone-block {
                    display: block;
                    padding-left: 0;
                    span {
                        display: none;
                    }
                    a {
                        display: inline-block;
                    }
                }

                .cellphone {
                    position: absolute;
                    bottom: 7px;
                    right: 50%;
                    transform: translateX(50%);
                }
            }

            @include bdown(xs) {
                .col:nth-child(2) {
                    display: block;
                }

                .cellphone {
                    bottom: 7px;
                    right: 80px;
                    transform: none;
                }

                .header-btn-wrap {
                    .btn {
                        &:first-child {
                            margin-right: 7px;
                        }

                        span {
                            font-size: 12px;
                        }
                    }
                }
            }

            @include bdown(xxs) {
                .header-contacts {
                    display: block;
                    text-align: center;
                }
            }
        }

        &-center {
            height: 100px;
            background: #fff;
            position: relative;
            .under-line {
                font-size: 0.51em;
                font-weight: 400;
            }
            &-logo-wrap {
                display: flex;
                align-items: center;
            }

            .input-wrap {
                margin-right: 70px;

                input {
                    &::placeholder {
                        color: rgba($base1, 0.9);
                    }
                }

                &:after {
                    position: absolute;
                    content: "";
                    right: -34px;
                    border-right: 1px solid rgba($base1, 0.5);
                    height: 23px;
                }
            }

            .title {
                color: $dark_blue_text;
                font-family: $montserrat;
                @include fluid-font(36px);
                font-weight: $bold;
                display: flex;
                flex-direction: column;
                padding-left: 100px;
            }

            .logo {
                width: 170px;
                height: 170px;
                position: absolute;
                left: -100px;
                top: -27px;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            @media screen and (max-width: 1750px) {
                &-logo-wrap {
                    .logo {
                        width: 150px;
                        height: 150px;
                        position: absolute;
                        left: -45px;
                        top: -20px;
                    }
                }
            }

            @media screen and (max-width: 1640px) {
                &-logo-wrap {
                    .logo {
                        width: 130px;
                        height: 130px;
                        position: absolute;
                        left: 10px;
                        top: -19px;
                    }
                }

                .title {
                    padding-left: 140px;
                }
            }

            @include bdown(nm) {
                .nav-container {
                    display: none;
                }

                .input-wrap {
                    flex: 1;
                    margin-right: 30px;

                    &:after {
                        display: none;
                    }
                }

                .title {
                }
            }

            @include bdown(md) {
                height: 80px;

                &-logo-wrap {
                    flex-direction: column-reverse;
                    align-items: flex-start;

                    .logo {
                        height: 130px;
                        width: 135px;
                        left: 6px;
                        top: -50px;
                    }
                }

                .title {
                    font-size: 22px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    padding-left: 100px;
                    padding-top: 10px;
                }
            }

            @include bdown(sm) {
                height: 40px;
                padding-top: 0;

                &-logo-wrap {
                    flex-direction: column-reverse;
                    align-items: flex-start;

                    .logo {
                        height: 94px;
                        width: 98px;
                        left: 6px;
                        top: -58px;
                    }
                }

                .title {
                    font-size: 16px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding-top: 0;
                }
            }

            @include bdown(xs) {
                &-logo-wrap {
                    .logo {
                        height: 80px;
                        width: 83px;
                        left: 6px;
                        top: -40px;
                    }
                }
            }
        }

        &-bottom {
            background: $base1;
            height: 55px;

            .menu-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0;

                .input-wrap {
                    width: auto;
                    flex: 1;
                    margin-right: 50px;

                    input {
                        &.input {
                            background: transparent;
                            border: none;
                            border-bottom: 1px solid
                                rgba($color: $white, $alpha: 0.5);
                            color: $white;
                            padding: 10px 30px 2px 0;
                        }

                        @include bdown(md) {
                            &::placeholder {
                                color: rgba($white, 0.5);
                            }
                        }
                    }

                    i {
                        color: rgba($white, 0.5);
                    }
                }
            }

            nav {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .dropdown {
                    position: relative;
                    span {
                        cursor: pointer;
                        white-space: nowrap;
                    }
                    @include bup(md) {
                        ul {
                            opacity: 0;
                            position: absolute;
                            height: 0;
                            overflow: hidden;
                            background-color: $base1;
                            left: -10%;
                            width: 120%;
                            z-index: 1;
                            transition: all 0.3s linear;
                            li {
                                border-bottom: 1px solid $white;
                                &:last-of-type {
                                    border-bottom: none;
                                }
                            }
                            a {
                                pointer-events: none;
                                font-size: 16px;
                                line-height: 18px;
                            }
                        }
                        &:hover ul {
                            top: 100%;
                            height: 66px;
                            opacity: 1;
                            a {
                                pointer-events: all;
                                line-height: 18px;
                            }
                        }
                    }
                }

                .dropdown span,
                a {
                    position: relative;
                    text-transform: uppercase;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 0 0 auto;
                    @include transition;
                    line-height: 26px;
                    @include fluid-font(23px);
                    font-weight: 400;
                    font-family: $rubik;
                    background-color: transparent;
                    padding: 0.4rem 0.7rem;
                    &.hidden {
                        display: none !important;
                    }

                    &.nav-item-active,
                    &:hover {
                        color: $dark_blue_text;
                    }
                }
            }

            @include bup(md) {
                nav {
                    padding: 10px 0;

                    a,
                    .dropdown span {
                        &.nav-item-active {
                            position: relative;

                            i {
                                font-size: 14px;
                                margin-left: 10px;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            @include bup(lg) {
                @include font-count(10, 22);
            }

            @include bdown(lg) {
                nav a,
                nav .dropdown span {
                    @include fluid-font(20px);
                    padding: 0.3rem 0.5rem;
                }
            }

            @include bdown(md) {
                font-size: 16px;
                height: auto;
                padding: 0;
                display: flex;
                align-items: center;

                .menu-container {
                    min-height: 55px;
                }

                .container {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    position: static;
                    padding-left: 0;
                    padding-right: 0;
                }

                .nav-container {
                    display: none;
                }

                nav {
                    flex-direction: column;
                    padding-left: 0;
                    padding-right: 0;
                    width: 100%;
                    justify-content: space-evenly;
                    background: $header_blue;
                    .dropdown span {
                        display: none;
                    }
                    a {
                        display: block;
                        font-size: 16px;
                        padding: 0.5em 5%;

                        i {
                            margin-left: 10px;
                            color: $white;
                        }

                        &.nav-item-active,
                        &:hover {
                            color: $base1;

                            &:after {
                                content: none;
                            }
                        }
                        &:last-of-type {
                            margin-left: 0;
                        }
                        &:before {
                            content: none;
                        }
                        &:after {
                            content: none;
                        }
                    }
                }
            }

            @include bdown(xs) {
                background: $white;
                padding: 4px 0;
            }
        }

        @include bbetween(md, lg) {
            .logo {
                height: 116px;
                width: 260px;
            }
        }

        @include bbetween(hg, hd) {
            .empty {
                display: none;
            }

            .need-wrap {
                flex-wrap: wrap;
            }
        }
    }
}

#logout-form {
    display: none;
}

.main-slider-loading {
    header {
        background-color: rgba(0, 0, 0, 0.8);
    }
}
