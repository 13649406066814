.btn {
  height: 60px;
  width: 235px;
  color: #fff;
  font-family: $rubik;
  font-weight: $medium;
  box-sizing: border-box;
  position: relative;
  padding: 10px 20px;
  @include fluid-font(17px);
  @include transition;
  line-height: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  //@include letter-spacing(160);
  border: none;
  outline: none;
  border-radius: 5px;
  user-select: none;
  text-decoration: none;

  &--wrap-right {
    text-align: right;

    @include bdown(md) {
      text-align: center;
    }
  }

  &--tile {
    background: $tile_btn;
    padding: 30px;
    margin-right: 70px;
    transition: all .3s;
    //max-width: calc(25% - 50px);

    @include bdown(nm) {
      margin-right: 50px;
    }

    @include bdown(md) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;

      &-wrap {
        flex-wrap: wrap;
      }

      &-line {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
      }
    }

    .label {
      &-big {
        font-family: $work-sans;
        font-weight: $semibold;
        @include fluid-font(30px);
        color: $dark_blue_text;
        margin-bottom: 10px;
        text-align: center;
        transition: all .3s;
      }

      &-small {
        font-family: $rubik;
        font-weight: $regular;
        @include fluid-font(19px);
        color: $text_minor;
        text-align: center;
        transition: all .3s;
      }
    }

    &:hover {
      background: $base1;

      .label {
        &-big {

        }

        &-small {
          color: $white;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &-wrap {
      display: flex;
    }

    &-line {
      margin-bottom: 40px;
    }
  }

  &.free-w {
    width: auto;
  }

  &.full-w {
    flex: 1;
  }

  .vertical-sep {
    height: 30px;
    margin-left: 12px;
    margin-right: 12px;

    &:after {
      border-right: 1px solid #fff;
    }
  }

  i {
    font-size: 22px;

    &.big {
      font-size: 30px;
    }
  }

  &-search {
    width: 200px;
    font-family: $karla;
    font-size: 16px;
    font-weight: $bold;

    i {
      margin-left: 30px;
      font-size: 30px;
    }
  }

  &-middle {
    height: 50px;
    width: 175px;
    @include fluid-font(16px);
  }

  &-green {
    background: $base1;

    &:hover {
      background: $dark_base1;
    }
  }

  &-nopadding {
    width: auto;
    height: auto;
    padding: 0;
  }

  &-white {
    background: $white;
    color: $blue_second_base;
    font-family: $work-sans;
    font-size: 14px;
    font-weight: 600;

    i {
      font-size: 22px;
      margin-right: 10px;
    }
  }

  &.brown-border {
    border: 2px solid $base1;
  }

  &.brown-icon {
    i {
      color: $base1;
    }
  }

  &-blue {
    background: $base2;

    &:hover {
      background: $dark_base2;
    }
  }

  &-darkblue {
    background: $dark_blue_text;

    &:hover {
      background: $dark_base2;
    }
  }

  &.big {
    height: 70px;
    @include fluid-font(24px);
  }

  &.extra-big {
    height: 90px;
    width: 100%;
    @include fluid-font(26px);
  }

  &--icon-calc {
    padding-left: 80px;

    &:before {
      display: block;
      content: "";
      background: url(../../images/calc.svg);
      background-size: cover;
      background-position: center;
      width: 40px;
      height: 55px;
      position: absolute;
      left: 20px;
    }
  }

  &--icon-people {
    padding-left: 80px;

    &:before {
      display: block;
      content: "";
      background: url(../../images/people.svg);
      background-size: cover;
      background-position: center;
      width: 40px;
      height: 52px;
      position: absolute;
      left: 20px;
    }
  }

  &--icon-man {
    padding-left: 80px;

    &:before {
      display: block;
      content: "";
      background: url(../../images/man.svg);
      background-size: cover;
      background-position: center;
      width: 50px;
      height: 50px;
      position: absolute;
      left: 20px;
    }
  }
}